import React from "react";
import { TopBottomModelProps, TopBottomDispatchProps } from "./update";
import { KommuneData } from "./kommuneData";
import {getCounty} from "./Tables";

export default function SearchBar({
  kommuneData,
  model,
  dispatch,
  searchInputRef,
}: {
  kommuneData: KommuneData[];
  searchInputRef: React.RefObject<HTMLInputElement>;
} & TopBottomModelProps &
  TopBottomDispatchProps) {

    const county = getCounty();
    const filteredKommuneData = kommuneData.filter(k => k.county === county);
  return (
    <div className="mx-4 mt-8 flex p-2 border border-solid border-gray-700 rounded">
      <datalist id="kommuner">
        {Array.from(new Set(filteredKommuneData.map((kommune) => kommune.name)))
          .map((name) => {
            return filteredKommuneData.find((kommune) => kommune.name === name);
          })
          .map((kommune) => (
            <option key={kommune.muni_id} value={`${kommune.name}`}>
              {kommune.name}
            </option>
          ))}
      </datalist>
      <input
        inputMode="search"
        placeholder="Søk i rangeringslista"
        type="search"
        list="kommuner"
        name="search"
        autoComplete="off"
        className="w-full pl-2"
        ref={searchInputRef}
        value={model.searchTerm}
        onChange={(e) => {
          e.preventDefault();
          dispatch({ type: "SearchKommune", payload: e.target.value });
        }}
      />
    </div>
  );
}
