import React from "react";
import ReactDOM from "react-dom";
import Filter from "./components/Filter";
import Tables from "./components/Tables";
import {
  topBottomReducer,
  topBottomInitialState,
  TopBottomModelProps,
  TopBottomDispatchProps,
} from "./components/update";
import { fetchAllKommuner } from "./components/api";
import { foldRemoteData } from "./components/remoteData";
import SearchBar from "./components/SearchBar";
import { KommuneData } from "./components/kommuneData";

function App() {
  const [model, dispatch] = React.useReducer(
    topBottomReducer,
    topBottomInitialState
  );

  React.useEffect(() => {
    async function getKommuneData() {
      dispatch({ type: "GetKommuneData", payload: { type: "LOADING" } });
      const kommuneData = await fetchAllKommuner();
      dispatch({ type: "GetKommuneData", payload: kommuneData });
    }
    getKommuneData();
  }, []);
  return foldRemoteData(
    model.kommuneData,
    () => <div>Ikke hentet data</div>,
    () => <div>Laster</div>,
    (error) => <div>Noe gikk galt: {error}</div>,
    (kommuneData) => (
      <SuccessView
        model={model}
        dispatch={dispatch}
        kommuneData={kommuneData}
      />
    )
  );
}

function SuccessView({
  model,
  dispatch,
  kommuneData,
}: { kommuneData: KommuneData[] } & TopBottomModelProps &
  TopBottomDispatchProps) {
    const searchInputRef = React.useRef<HTMLInputElement>(null);
    const searchResultHitRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      function scrollToResult() {
        searchResultHitRef?.current?.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "nearest"
        })
      }


      searchInputRef.current?.addEventListener("keypress", (e) => {
        if (e.keyCode === 13 || e.which === 13) {
          scrollToResult()
          searchInputRef.current?.blur()
        }
      })

      searchInputRef.current?.addEventListener("keyup", (e) => {
        if (e.keyCode === 13 || e.which === 13) {
          scrollToResult()
          searchInputRef.current?.blur()
        }
      })
      searchInputRef.current?.addEventListener("focusout", () => {
        scrollToResult()
      })
  }, [searchInputRef, searchResultHitRef])


  return (
    <>
      <SearchBar model={model} dispatch={dispatch} kommuneData={kommuneData} searchInputRef={searchInputRef} />
      <Filter model={model} dispatch={dispatch} />
      <Tables model={model} dispatch={dispatch} kommuneData={kommuneData} searchResultHitRef={searchResultHitRef} />
      <div className="flex-links my-8">
          <div>
            <a className="underline" href="/">
              Se alle fylker
            </a>
          </div>
          <div className="text-right">
            <button className="underline" onClick={() => {dispatch({type: "ViewAll"})}}>
              Se hele listen
            </button>
          </div>
        </div>
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("top-bottom"));
