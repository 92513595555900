import React from "react";
import { TopBottomModelProps, TopBottomDispatchProps } from "./update";
import * as R from "remeda";
import { KommuneData } from "./kommuneData";
import { Criteria, criterias } from "./criterias";
import { ScoreToSmiley } from "./Smiley";

// Henter fylke ut i fra URL.
export function getCounty() {
  const fragments = document.location.href.split('/');
  return decodeURI(fragments[fragments.length - 1]);
}

function byCounty(kommuner: KommuneRankData[], county: string): KommuneRankData[] {
  if (county === '')
    return kommuner;

  // Konverter rangering fra Norgesbasis til Fylkesbasis.
  // F.eks Sør-Varanger er #16 i norge, men den er #2 i Troms og Finnmark.
  // byCounty funksjonen forventer at input allerede er sortert på fylke.
  let filtered = kommuner.filter(k => k.county === county);
  for (let idx = 0; idx < filtered.length; idx++) {
    filtered[idx].placement = idx + 1;
  }

  return filtered;
}

export default function Tables({
  kommuneData,
  model,
  searchResultHitRef,
  dispatch
}: { kommuneData: KommuneData[], searchResultHitRef : React.RefObject<HTMLDivElement> } & TopBottomModelProps &
  TopBottomDispatchProps) {
  let reversedKommuner = R.pipe(kommuneData, R.reverse());

  const kommuneRankData: KommuneRankData[] = byCounty(R.pipe(
    reversedKommuner,
    (kommune) => kommuneDataListToKommuneRankData(kommune, model.filter),
    R.filter((kommuneRank) => kommuneRank.score !== null),
    R.sortBy((kommune) => kommune.placement)
  ), getCounty());

  const searchMatches = R.pipe(
    reversedKommuner,
    (kommuneMatches) => kommuneDataListToKommuneRankData(kommuneMatches, model.filter),
    R.sortBy((kommune) => kommune.placement),
    R.drop(5),
    R.dropLast(5),
    (kommune) => returnExcactOrFuzzyMathes(kommune, model.searchTerm)
  );

  const showMoreButtonStyle = {border: "none", background: "none", padding: "0.6rem 1rem", margin: "0.5rem auto", justifySelf: "center"};

  const isSingleEntry = kommuneRankData.length === 1; // Typically when filtering by "Oslo" on /fylker/
  let listMode = isSingleEntry ? "view_all" : model.listMode;

  return (
    <div className="m-4" ref={searchResultHitRef}>
      <KommuneTable
        kommuneRankData={kommuneRankData.slice(0, 5)}
        model={model}
        header={true}
        disableBold={searchMatches.length > 0} 
      />
      {listMode === "view_filtered" &&
      searchMatches.length > 0 &&
      model.searchTerm.length > 1 ? (
          <KommuneTable
            kommuneRankData={searchMatches}
            model={model}
            allHightlighted={true}
            removeOuterBorders={true}
            disableBold={false}
          />
      ) : listMode !== "view_all" ? (
          <button style={showMoreButtonStyle} title={"Vis alle kommuner"} className="flex justify-center items-center" onClick={() => {dispatch({type: "ViewAll"})}}><GapSeparator /></button>
      ) : (
        <></>
      )}
      {listMode === "view_all" && (
        <KommuneTable
          kommuneRankData={R.pipe(kommuneRankData, R.drop(5), R.dropLast(5))}
          model={model}
          removeOuterBorders={true}
          disableBold={true} 
        />
      )}
      {!isSingleEntry && <KommuneTable kommuneRankData={kommuneRankData.slice(-5)} model={model} disableBold={searchMatches.length > 0} />}
    </div>
  );
}

function KommuneTable({
  model,
  kommuneRankData,
  allHightlighted = false,
  removeOuterBorders = false,
  header = false,
  disableBold = false
}: TopBottomModelProps & {
  kommuneRankData: KommuneRankData[];
  allHightlighted?: boolean;
  removeOuterBorders?: boolean;
  header?: boolean;
  disableBold: boolean;
}) {

  const showExtraColumns = kommuneRankData[0]?.nature_protection_score || kommuneRankData[0]?.usage_of_funding_score;

  return (
    <table
      className={`table table-auto w-full ${
        removeOuterBorders ? "border-b-0" : "border-b-2"
      }`}
    >
      {header && (
        <thead>
          <tr className="text-xs">
            <td className="py-2">
              <div className="w-5">Plassering</div>
            </td>
            <td className="pl-10 w-full">Kommune</td>
            {showExtraColumns && <>
              <td className="hide-on-mobile">Satser</td>
              <td className="hide-on-mobile">Beskytter</td>
            </>}
            <td>Sammenlagt</td>
          </tr>
        </thead>
      )}
      <tbody>
        {R.pipe(
          kommuneRankData,

          R.map(({ id, name, placement, score, nature_protection_score, usage_of_funding_score, county }) => (
            <tr
              key={id}
              className={`border-t-2 ${
                removeOuterBorders ? "first:border-t-0" : ""
              } px-4 ${
                model.searchTerm.length > 1 &&
                ((name.toLowerCase().trim().includes(model.searchTerm.toLowerCase().trim()) ) ||
                  (model.searchTerm.includes("(") &&
                    name.trim()
                      .toLowerCase()
                      .includes(
                        model.searchTerm
                          .substr(0, model.searchTerm.indexOf("("))
                          .trim()
                          .toLowerCase()
                      ) ))
                  ? `bg-primary-100 ${disableBold ? "" : "font-bold"}`
                  : ``
              } ${allHightlighted && !model.searchTerm.includes("(") && !disableBold ? "bg-primary-100" : ""} ${allHightlighted ? "bg-primary-100" : ""}`} 
            >
              <td className="pl-2 py-2">
                <div className="w-5">{placement}.</div>
              </td>
              <td className="pl-10 w-full">
                <a className="divide-red-600 underline" href={`${getCounty()}/kommune/${id}`}>
                  {name === "Herøy" || name === "Våler"
                    ? `${name} (${county})`
                    : name}
                </a>
              </td>
              {showExtraColumns &&
                <>
                  <td className="pr-8 text-right whitespace-no-wrap hide-on-mobile">{ usage_of_funding_score ? (rounded(usage_of_funding_score) + " %") : ""}</td>
                  <td className="pr-8 text-right whitespace-no-wrap hide-on-mobile">{ nature_protection_score ? (rounded(nature_protection_score) + " %") : ""}</td>
                </>
              }
              <td className="pr-8 text-right font-bold whitespace-no-wrap">{rounded(score)} %</td>
              <td>
                <div style={{ width: "20px" }}>
                  <ScoreToSmiley
                    score={score}
                    maxScore={criterias[model.filter].max_score}
                  />
                </div>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}

interface KommuneRankData {
  score: number;
  nature_protection_score?: number;
  usage_of_funding_score?: number;
  placement: number;
  id: string;
  name: string;
  county: string;
}

function kommuneDataListToKommuneRankData(
  kommuneDataList: KommuneData[],
  criteriaFilter: Criteria
): KommuneRankData[] {
  if (criteriaFilter !== "total") {
    return R.pipe(
      kommuneDataList,
      R.map((kommune) => ({
        score: kommune.metrics.category_scores[criteriaFilter]!,
        placement: kommune.metrics.category_placements[criteriaFilter]!,
        id: kommune.muni_id,
        name: kommune.name,
        county: kommune.county,
      }))
    );
  } else {
    return R.pipe(
      kommuneDataList,
      R.map((kommune) => ({
        score: kommune.metrics.total_score,
        usage_of_funding_score: kommune.metrics.category_scores["usage_of_funding"],
        nature_protection_score: kommune.metrics.category_scores["nature_protection"],
        placement: kommune.metrics.final_placement,
        id: kommune.muni_id,
        name: kommune.name,
        county: kommune.county,
      }))
    );
  }
}

function GapSeparator() {
  return (
    <div className="flex justify-center items-center">
      <svg
        width="16"
        height="4"
        viewBox="0 0 16 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="2" cy="2" r="2" fill="#BBBBBB" />
        <circle cx="8" cy="2" r="2" fill="#BBBBBB" />
        <circle cx="14" cy="2" r="2" fill="#BBBBBB" />
      </svg>
    </div>
  );
}

function rounded(num: number): string {
  return (Math.round(num * 100) / 100).toFixed(2).replace(".", ",");
}

// Returns exact matches, and if none, return partial matches
function returnExcactOrFuzzyMathes(
  kommuneRankDataList: KommuneRankData[],
  searchTerm: string
): KommuneRankData[] {
  const excactMatches = R.pipe(
    kommuneRankDataList,
    R.filter(
      (kommune) => kommune.name.toLowerCase().trim() === searchTerm.toLowerCase().trim()
    )
  );

  if (searchTerm === "") {
    return [];
  }

  if (excactMatches.length === 1) {
    const kommuneSelector = (kommune: KommuneRankData) => kommune.name.toLowerCase().trim() === searchTerm.toLowerCase().trim()

    const matchIndex = R.findIndex(kommuneRankDataList, kommuneSelector);
    return getFromIndexes(kommuneRankDataList, adjacentIndexes(matchIndex))
  }

  if (excactMatches.length > 1) {
    return excactMatches;
  }

  if (searchTerm.includes("(") && searchTerm.includes(")")) {
    const fylke = searchTerm.substring(
      searchTerm.lastIndexOf("(") + 1,
      searchTerm.lastIndexOf(")")
    );
    const kommuneName = searchTerm.substr(0, searchTerm.indexOf("(")).trim();

    const kommuneSelector = (kommune: KommuneRankData) =>
      kommune.county === fylke && kommune.name === kommuneName;

    const matchIndex = R.findIndex(kommuneRankDataList, kommuneSelector);
    const indexes = adjacentIndexes(matchIndex);

    if (indexes.length < 3) getFromIndexes(kommuneRankDataList, [matchIndex]);
    else return getFromIndexes(kommuneRankDataList, indexes);
  }



  if (excactMatches.length === 0) {
    const kommuneSelector = (kommune: KommuneRankData) =>
      kommune.name.toLowerCase().includes(searchTerm.toLowerCase());

    const matchIndex = R.findIndex(kommuneRankDataList, kommuneSelector);

    const fuzzyMatches = R.pipe(kommuneRankDataList, R.filter(kommuneSelector));
    if (fuzzyMatches.length === 1) {
      return getFromIndexes(kommuneRankDataList, adjacentIndexes(matchIndex));
    }
    return fuzzyMatches;
  }

  return [];
}

function adjacentIndexes(index: number) {
  return [index - 1, index, index + 1];
}

function getFromIndexes<T>(data: T[], indexes: number[]): T[] {
  return R.pipe(
    data,
    R.filter.indexed(
      (_, index) => indexes.find((i) => i === index) !== undefined
    )
  );
}
