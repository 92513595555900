import React from "react";
import { TopBottomModelProps, TopBottomDispatchProps } from "./update";
import { criterias, Criteria } from "./criterias";

export default function Filter({
  model,
  dispatch,
}: TopBottomModelProps & TopBottomDispatchProps) {
  const updateCriteria: React.Dispatch<Criteria> = (
    payload: Criteria
  ) => dispatch({ type: "SetCriteriaFilter", payload });
  return (
    <div className="overflow-x-auto p-4 whitespace-no-wrap">
      {(Object.keys(criterias) as Criteria[]).map((criteria) => (
        <CriteriaChip
          key={criteria}
          name={criterias[criteria].name ?? criteria}
          criteria={criteria}
          selectedCriteria={model.filter}
          updateCriteria={updateCriteria}
        />
      ))}
    </div>
  );
}

function CriteriaChip({
  name,
  criteria,
  selectedCriteria,
  updateCriteria,
}: {
  name: string;
  criteria: Criteria;
  selectedCriteria: Criteria;
  updateCriteria: React.Dispatch<Criteria>;
}) {
  const backgroundColor = selectedCriteria === criteria ? "bg-primary-700 border-none" : "bg-white";
  return (
    <button
      onClick={() => {
        updateCriteria(criteria);
      }}
      className={`${backgroundColor}  chip rounded-full`}
    >
      {name}
    </button>
  );
}
