import { KommuneData } from "./kommuneData";

type CriteriaMap = {
  [key in Criteria]: {
    name: string | undefined;

    max_score: number;
  };
};

// TODO: Could possibly be provided from a JSON endpoint
export const criterias: CriteriaMap = {
  total: {
    name: "Sammenlagt",
    max_score: 140,
  },
  usage_of_funding: {
    name: "Satser kommunen på naturen?",
    max_score: 100,
  },
  nature_protection: {
    name: "Beskytter kommunen naturen?",
    max_score: 100,
  },
};

export type Criteria =
  | keyof KommuneData["metrics"]["category_scores"]
  | "total";
