import React from "react";

export function ScoreToSmiley({
  score,
  maxScore,
}: {
  score: number;
  maxScore: number;
}): JSX.Element {
  const scoreChunk = maxScore / 5;
  if (score <= scoreChunk) {
    return <VeryBad />;
  } else if (score <= scoreChunk * 2) {
    return <MediumBad />;
  } else if (score <= scoreChunk * 3) {
    return <Medium />;
  } else if (score <= scoreChunk * 4) {
    return <MediumGood />;
  } else if (score <= scoreChunk * 5) {
    return <VeryGood />;
  } else {
    return <>?</>;
  }
}

const svgImgStyle = "min-h";

function VeryBad() {
  return <img className="smiley" src="/icons/face-5.png" />;
}

function MediumBad() {
  return <img className="smiley" src="/icons/face-4.png" />;
}

function Medium() {
  return <img className="smiley" src="/icons/face-3.png" />;
}

function MediumGood() {
  return <img className="smiley" src="/icons/face-2.png" />;
}

function VeryGood() {
  return <img className="smiley" src="/icons/face-1.png" />;
}
